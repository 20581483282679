import React from 'react';
import '../components/layout.css';

const ContentAllExceptIndex = ({ children, loc, title }) => {
	const data={
		types:{
			about:"about",
			branch:"branch",
			news:"news",
			contacts:"contacts",
			privacy:"privacy"
		}
	}
	switch (title) {
		case data.types.about:
			return (
				<React.Fragment>
					<div id='cortex-content-all-except-index-about'>
						<div className='cortex-content-all-main'> {children}</div>
					</div>
				</React.Fragment>
			);
		case data.types.branch:
			return (
				<React.Fragment>
					<div id='cortex-content-all-except-index-about'>
						<div className='cortex-content-all-main'> {children}</div>
					</div>
				</React.Fragment>
			);
		case data.types.news:
			return (
				<React.Fragment>
					<div
						id={
							loc === '/news' ? 'cortex-content-for-news' :
							'cortex-content-all-except-index-news'
						}
						>
						<div className='cortex-content-all-main'> {children}</div>
					</div>
				</React.Fragment>
			);
		case data.types.contacts:
			return (
				<React.Fragment>
					<div id='cortex-content-all-except-index-contacts'>
						<div className='cortex-content-all-main'> {children}</div>
					</div>
				</React.Fragment>
			);
		case data.types.privacy:
			return (
				<React.Fragment>
					<div id='cortex-content-all-except-index-privacy'>
						<div className='cortex-content-all-main'> {children}</div>
					</div>
				</React.Fragment>
			);
		default:
			return title;
	}
};

export default ContentAllExceptIndex;
